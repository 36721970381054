<template>
  <div>
    <div class="no-scrollbar text-shadow" style="white-space:nowrap;font-size:14px;margin:0;overflow:auto;opacity: 0.8;">
      <ul class="list-unstyled text-shadow" style="white-space:nowrap;font-size:14px;margin:0;">
        <template v-if="hasGroup()">
          <template v-for="(item,index) in form.groups">
            <template v-if="showHide?(showGroupType?showGroupType.indexOf(item.type)!=-1||showGroupType==item.type:true):!item.hide&&(showGroupType?showGroupType.indexOf(item.type)!=-1||showGroupType==item.type:true)">
              <li v-if="!(getGroupItems(item.guid).length==0 && !item)" class="hotspotItem" style="display:inline-block;width:auto;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
                <div style="position:relative;cursor:pointer;" class="text-truncate group" :class="{'active':groupGuid==item.guid}" @click="setEditGroup(item)">
                  <a style="color:#fff;display:block" :class="{ 'text-danger':!checkGroupUniqueName(item) }">
                    <!--<span class="badge bg-danger"></span>-->
                    <div class="text-truncate" style="text-align:center;font-size:12px;">
                      <template v-if="item.type=='Blog'">
                        <i class="far fa-file-alt"></i>
                      </template>
                      <template v-else-if="item.type=='Gallery'">
                        <i class="far fa-images"></i>
                      </template>
                      <template v-else>
                        <span v-if="groupGuid==item.guid">
                          <i class="far fa-folder-open"></i>
                        </span>
                        <span v-else>
                          <i class="far fa-folder"></i>
                        </span>
                      </template>
                      {{item.title||'[未命名]'}}
                    </div>
                  </a>
                </div>
              </li>
            </template>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
  import { uuid } from 'vue-uuid'
  import PellEditor from '../../PellEditor'

  export default {
    components: {
      PellEditor
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        //groups: []
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      form() {
        return this.publicData.form || {}
      },
      $v() {
        return this.publicData.$v
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      showGroupType() {
        return this.config.showGroupType
      },
      showHide() {
        return this.config.showHide || false
      },
      groups() {
        return this.form.groups || []
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          getGroup: this.getGroup,
        },
        events: {
          showEditModel: {
            target: 'groupSettingModel',
            name: 'show'
          }
        }
      })
    },
    destroyed() {
    },
    methods: {
      setEditGroup(item) {
        var groupGuid = item.guid
        if (item.type == 'Blog') {
          this.getFunc('renewSence')('blog')
        } else if (item.type == 'Gallery') {
          this.getFunc('renewSence')('gallery')
        } else {
          //  this.getFunc('renewSence')('default')
        }

        if (this.groupGuid == groupGuid && groupGuid) {
          //  this.$emit('showEditModel')
        }
        //this.changeGroup(groupGuid)
        this.getFunc('changePublicData')({ groupGuid, })
        //  this.$forceUpdate()
      },
      getGroup(groupName) {
        console.log(groupName, this.groups)
        for (var i in this.groups) {
          if (this.groups[i].title == groupName) {
            return this.groups[i]
          }
        }
      },
      groupMove(item, relative) {
        console.log(item, relative)
        var fromi = null
        var group = null
        for (var i in this.form.groups) {
          if (this.form.groups[i].guid == item.guid) {
            fromi = Number(i)
            group = this.form.groups[i]
          }
        }
        var to = fromi + relative
        this.form.groups.splice(fromi, 1)
        this.form.groups.splice(to, 0, group)
      },
      //changeGroup(guid) {
      //  console.log('changeGroup', guid)
      //  var groupItems = this.getFunc({ target: 'Data', name: 'getGroupItems' })(guid)
      //  this.getFunc('changePublicData')({ groupItems, })
      //},
      hasGroup() {
        for (var i in this.groups) {
          if (this.groups[i].title) {
            return true
          }
        }
        return false
      },
      getGroupItems(groupGuid) {
        return this.getFunc({ target: 'Data', name: 'getGroupItems' })(groupGuid) || []
      },
      checkGroupUniqueName(p) {
        for (var i in this.form.groups) {
          var item = this.form.groups[i]
          if (item.guid != p.guid && item.title == p.title) {
            return false
          }
        }
        return true
      },
    },
  }
</script>
<style scoped>
  .GroupItem {
    display: inline-block;
    width: auto;
    margin: 2px;
    pointer-events: auto;
    vertical-align: top;
    position: relative;
  }

  .group {
    border-bottom: 2px solid #0000;
    /*background-color: #0008;*/
    padding: 2px 4px;
  }

    .group.active {
      border-bottom: 2px solid #ffb400;
      /*background-color: #00f8*/
    }

  .arrowbtn {
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    color: #fff;
    outline: 0;
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;
    width: 4.1em;
    vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }
</style>
